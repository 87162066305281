/*
Version 0.100

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2018
	17-10-2018
	- [BORN] This class is born. Hooray!

!Usage

	-
	
!Wishlist

	none
	
*/
  
var specific_interactions_init = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	/**
	None yet
	**/
	 
	// PRELOAD & DOCUMENT READY  *****************************************************************
	// PRELOAD & DOCUMENT READY *****************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	// PRELOAD & DOCUMENT READY ******************************************************************
	
	this.onready = function() {
		
		/**
		Unbind: Unnecessary listeners
		**/
		//$(document).off('click.cookieWallSubmit');
		//$(document).off('click.cookieWallTabs');
		//$(document).off('click.cookieBarSubmit');
		
		/*
		Close the mobilemenu always on route
		*/
		specific_mobilemenu.toggle(1);

		/*
		Slideshows
		*/
		specific_slideshows.onready();

		/*
		Trigger: Instagram
		*/
		//specific_instagram.onready('thumb',8,true);

		/*
		Trigger: Facebook
		*/
		//specific_facebook.onready(6,true);
		
		/*
		Catch and active URL
		*/
		// var _url_active_main = './';
		
		// $('.js-nav-item').removeClass('active');

		// if(url[2] !== '') { 
		// 	_url_active_main = url[2];
		// }

		// if(url[3] !== '') {
		// 	_url_active_main = url[2] + '/' + url[3];
		// }
		
		// if(_url_active_main !== '') {
		// 	$('.js-nav-item[data-go-url="' + _url_active_main + '"]').addClass('active');
		// }

		/*
		Popups
		*/
		if(url[1] == 'diensten' && url[2] != '') {
			popup.open(1,1,'diensten/' + url[2]);
		}
		if(url[1] == 'aanpak' && url[2] != '') {
			popup.open(1,1,'aanpak');
		}
		if(url[1] == 'over-mij' && url[2] != '') {
			popup.open(1,1,'over-mij');
		}

		/*
		Init: Infinity slider
		*/
		$('.js-infiniteslide').infiniteslide({
			'speed': 100,
			'direction': 'left',
			'pauseonhover': true,
			'responsive': true,
			'clone': 1
		});
        	
        /*
		Init: Tooltipster
		*/
        $('.js-tooltip').tooltipster({
        	delay: 0
        });

		/*
		Init: Swiper
		*/
		var swiper = new Swiper('.js-swiper',{
			navigation: {
			  nextEl: '.js-slide-next',
			  prevEl: '.js-slide-prev',
			},
			observeParents: true,
			loop:true,
			autoHeight: true
		});

		/*
		Init: Scroller
		*/
		specific_scroller.onready();

	};
	
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	// METHODS *****************************************************************
	
	// none
	
}).apply(specific_interactions_init); 
