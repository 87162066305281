/*
Version 0.115

Made by: Finally // Aleks Schakel // www.finally.nl // aleks@finally.nl
Copyright 2016 - 2018.

!Changelog

2021
	20-01-2021
	- [BORN] This class is born. Hooray!

!Usage
	specific_mobilemenu.onready();

	
!Wishlist

	none
	
*/
  
var specific_scroller = {};

(function(){
	 
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	// CONSTRUCTOR ********************************************************************
	
	// none
	 
	// METHODS  *****************************************************************
	// METHODS *****************************************************************
	// METHODS ******************************************************************x
	// METHODS ******************************************************************
	
	this.onready = function() {

		var _marge;

		switch(url[1]) {

			default:
			break;

			case 'aanpak':

				_class = '.aanpak';
				
				if($(window).width() > 500) {
					_marge = 70;
				} else {
					_marge = 0;
				}

				$('html,body').stop().animate({
			        scrollTop: $(_class).offset().top - _marge
			    },1000);

			break;

			case 'diensten':

				if($(window).width() > 500) {
					_marge = 270;
				} else {
					_marge = 0;
				}

				_class = '.services';

				$('html,body').stop().animate({
			        scrollTop: $(_class).offset().top - _marge
			    },1000);

			break;

			case 'over-mij':
		
				if($(window).width() > 500) {
					_marge = 70;
				} else {
					_marge = 0;
				}
				
				_class = '.about';

				$('html,body').stop().animate({
			        scrollTop: $(_class).offset().top - _marge
			    },1000);

			break;

			case 'contact':

				if($(window).width() > 500) {
					_marge = 70;
				} else {
					_marge = 0;
				}
				
				_class = '.js-contact';

				$('html,body').stop().animate({
			        scrollTop: $(_class).offset().top - _marge
			    },1000);

			break;

		}


	};

}).apply(specific_scroller); 